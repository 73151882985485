import React from 'react';
import { navigate } from 'gatsby';
import { Text } from '../components/text';
import * as layoutStyles from '../components/css/layout.module.css';

const Success = (props) => {
  const isBrowser = typeof window !== 'undefined';

  if (isBrowser) {
    if (!props.location.state) navigate('/');

    setTimeout(() => {
      navigate('/');
    }, 2000);
  }
  return (
    <div
      className={`${layoutStyles.textContainer} ${layoutStyles.subContainer}`}
    >
      <h3>
        <Text tid='success' />
      </h3>
    </div>
  );
};

export default Success;
